'use strict';

class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.displayAllNotification = this.displayAllNotification.bind(this);
    this.getNbNotifications = this.getNbNotifications.bind(this);
    this.getVisibilityNbNotifications = this.getVisibilityNbNotifications.bind(this);
    this.getStyleBadge = this.getStyleBadge.bind(this);
  }

  componentDidMount() {
    $('#languageSelector').on('change', function() {
      if ( this.value == 'Français')
      {
        changeLanguageSession("FR")
      }
      if ( this.value == 'English')
      {
        changeLanguageSession("EN")
      }
    });

    try{
      changeLanguage($("#language").val());
    }
    catch(ex){
    }
   
    try{
      $("#btnNavDeconnexion").on("click", disconnect);

      if (typeof objectNavBar !== 'undefined' && objectNavBar) {
        switch(objectNavBar.right){
          case "clinic":
            $("#settingsIconNav").css("visibility","visible");
            $("#notificationIconNav").css("visibility","visible");
            break;
          case "patientInvite":
            $("#btnNavAccount").css("display","none");
            $("#btnNavDeconnexion").attr("onclick","disconnectInvite()");
            break;
        }
        
        if(objectNavBar.notifications){
          this.setState({ notifications: objectNavBar.notifications, optionClinic: objectNavBar.optionClinic });
        }

      }
    }
    catch(ex){
    }
  }

  displayAllNotification(){
    $.ajax( {
      url: '/api/notification/getNotifications',
      context: this,
      type: 'POST',
      success(data) {
        this.setState({ notifications:data });
        let timer = false;
        if(this.state.optionClinic && this.state.optionClinic.modeTimerNotification){
            timer = true;
        }
       
        if(this.state.notifications){
          Notification.fromHideAll(timer);
          for(let notification of this.state.notifications){
            new Notification(notification, timer );
          }
        }else{
           Notification.fromDefault(timer) ;
        }
      },
      error(error) {
        showErreurModal(buildErrorMessage(error));
      }
    });
  }

  getNbNotifications(){
    if(this.state && this.state.notifications){
      return this.state.notifications.length;
    }
    return 0;
  }

  getVisibilityNbNotifications(){
    if(this.getNbNotifications() < 1){
      return "hidden";
    }

    return "visible";
  }

  getStyleBadge(){
    return {
      position:"relative",
      left: "40px",
      visibility:this.getVisibilityNbNotifications()
    };
  }

  render() {
    const script1Nav = document.createElement("script");
    script1Nav.src = "/libs/bootstrap-notify.min.js";
    document.body.appendChild(script1Nav);

    const style2Nav = document.createElement("link");
    style2Nav.href = "/libs/animate.min.css";
    style2Nav.rel="stylesheet";
    style2Nav.type="text/css";
    document.body.appendChild(style2Nav);

    return (
      <nav className = "navbar navbar-light  bg-info">
        <select className="form-control col-2" id="languageSelector">
            <option value="Français" >Français </option>
            <option value="English" >English </option>
          </select>
          <h2 onClick = {() => window.location="/manageQuestionnaire"} >
            <a href="/manageQuestionnaire">
              <span className="text-light" id="btnNavHome" /> 
            </a>
          </h2>
          <div className="d-flex justify-content-around">
            <a
              onClick={this.displayAllNotification}
              href= "#"
              title= "Notification"
              style={{visibility: "hidden"}}
              id="notificationIconNav"
            > 
              <span className="badge badge-secondary" style={this.getStyleBadge()} >
                {this.getNbNotifications()}
              </span>
              <i style={{fontSize:"34px", color:"white"}} className= "fa fa-bell ">
              </i> 
            </a>
            &nbsp;&nbsp;
            
            <a
              href= "/clinicSettings"
              title= "Settings"
              style={{visibility: "hidden"}}
              id="settingsIconNav"
            > 
              <i style={{fontSize:"36px", color:"white"}} className= "fa fa-cog " />
            </a>
            &nbsp;
            <button className="btn btn-outline-light" id="btnNavAccount" disabled= {true} />
            &nbsp;
            <button className="btn btn-danger" id="btnNavDeconnexion" />
          </div> 
      </nav>
    );
  }
}

$(function(){
  const domContainerNav = document.querySelector('#navContainer');
  ReactDOM.render(<Navbar></Navbar>, domContainerNav);
});

function disconnect(){
  let isModalWaitingStarted = prepareModalWaiting();
  $.ajax( {
    url: '/api/utilisateur/disconnect',
    type: 'POST',
    success(data) {
      hideModalWaiting(isModalWaitingStarted);
      window.location="/login";
    },
    error(error) {
      hideModalWaiting(isModalWaitingStarted);
      showErreurModal(buildErrorMessage(error));
    }
  });
  showModalWaiting(isModalWaitingStarted);
}

function disconnectInvite(){
  let isModalWaitingStarted = prepareModalWaiting();
  $.ajax( {
    url: '/api/utilisateur/disconnectInvite',
    type: 'POST',
    success(data) {
      hideModalWaiting(isModalWaitingStarted);
      window.location.href="/manageQuestionnaire";
    },
    error(error) {
      hideModalWaiting(isModalWaitingStarted);
      showErreurModal(buildErrorMessage(error));
    }
  });
  showModalWaiting(isModalWaitingStarted);
}

function changeLanguageSession(langue){
  let isModalWaitingStarted = prepareModalWaiting();
  $.ajax( {
    url: '/api/questionnaire/setLanguage',
    type: 'POST',
    data: {
      language:langue
    },
    complete: function (error){
      hideModalWaiting(isModalWaitingStarted);
    }
  });
  showModalWaiting(isModalWaitingStarted);

  changeLanguage(langue);
}

function changeLanguage(langue){
  if (langue==="EN"){
    $('#languageSelector').val($('#languageSelector > option:last').val());
    $('#btnNavDeconnexion').text("Log out");
    $('#btnNavHome').text("Home");
    $('#btnNavAccount').text("My Account");
  } else {
    $('#languageSelector').val($('#languageSelector > option:first').val());
    $('#btnNavDeconnexion').text("Déconnexion");
    $('#btnNavHome').text("Accueil");
    $('#btnNavAccount').text("Mon Compte");
  }

  traduction(langue);
}

class Notification {
  constructor(notification, timer) {
    this.notification = notification;
    this.delay = timer ;
    this.displayNotification();
  }

  displayNotification(){
    $.notify({
      title: `<strong>${this.notification.title}</strong>`,
      message: `<br>${this.notification.message}<br> <br> ${this.getLinkPatient()} ${this.getCheckBoxHtml()}`,
    },
    this.getSettingNotify()
    );
  }

  getCheckBoxHtml(){
    return  `<div class="form-check">
              <input type="checkbox" onclick="hideNotification(${this.notification.idNotification})" class="form-check-input"  name="notifyHideMe-${this.notification.idNotification}">
              <label class="form-check-label" for="notifyHideMe-${this.notification.idNotification}">${traductionPhrase("Ne plus voir","Hide")}</label>
            </div>`;
  }

  getLinkPatient(){
    if(this.notification.idPatient){
      return `<a href="/managePatientTask?id=${this.notification.idPatient}">${traductionPhrase("Lien vers dossier patient","Link to patient file")}</a>
      <br>`;
    }
    
    return ``;
  }

  getSettingNotify(){
     return Notification.getDefaultSetting(this.notification.type, this.delay);
  }

  static fromDefault(timer){
    $.notify({
      title: `<strong>${traductionPhrase("Vous n'avez pas de notifications","You have no notifications")}</strong>`,
      message: ""
    },
      Notification.getDefaultSetting(null, timer)
    );
  }

  static fromHideAll(timer){
    $.notify({
      title: `<strong>${traductionPhrase("Cacher toutes les notifications","Hide all notifications")}</strong>`,
      message: `<div class="form-group"><button class="btn btn-link" onClick="hideAllNotification()">${traductionPhrase("Cacher tout","Hide all")}</button></div>`
    },
      Notification.getDefaultSetting(null, timer)
    );
  }

  static getDefaultSetting(type, delay){
    return  {
      // settings
      element: 'body',
      position: null,
      type: type?type:"secondary",
      allow_dismiss: true,
      newest_on_top: false,
      showProgressbar: false,
      placement: {
        from: "top",
        align: "right"
      },
      offset: 20,
      spacing: 10,
      z_index: 1031,
      delay: delay ? 8000 : 0,
      timer: 1000,
      url_target: '_blank',
      mouse_over: null,
      animate: {
        enter: 'animated bounceInDown',
        exit: 'animated bounceOutUp'
      },
      onShow: null,
      onShown: null,
      onClose:null,
      onClosed: null,
      icon_type: 'class',
    };
  }

}

function hideNotification(idNotification){
  var hide;

  if( $(`[name='notifyHideMe-${idNotification}']`).is(':checked')  ){
    hide = true;
  }else{
    hide = false;
  }

  $.ajax( {
    url: '/api/notification/hideNotification',
    type: 'POST',
    data: {
      idNotification:idNotification,
      hide: hide
    }
  });
}

function hideAllNotification(){
  $.ajax( {
    url: '/api/notification/hideAllNotification',
    type: 'POST',
  } );
  $.notifyClose();
}

